import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";
import {Link, useLocation} from "react-router-dom";

export default function Page404() {
    const location = useLocation();

    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Box display={"flex"} alignItems={"center"} height={"calc(100VH - 64px)"}>
                <Box>
                    <Typography variant={"h2"}>頁面不存在</Typography>
                    <Typography
                        variant={"body1"}>URL：{(window.location.protocol) + "//" + window.location.host + location.pathname}</Typography>
                    <Box sx={{mt: 2}}/>
                    <Button component={Link} to={"/"}>回首頁</Button>
                    <Typography variant={"body2"}>如果你認為這是一個可怕的錯誤，請聯繫客服人員。</Typography>
                </Box>
            </Box>
        </Box>
    )
}