import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {Button, FormControl, Input, InputAdornment} from "@mui/material";

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import axios from "axios";

export default function Item({id, name, price, qty, images, description}) {

    const [count, setCount] = React.useState(1);

    return (
        <Card sx={{maxWidth: 345}}>
            <CardHeader
                title={name}
                subheader={qty > 0 ? "有庫存" : "無庫存"}
            />
            <CardMedia
                component="img"
                height="194"
                image={images[0]}
                alt="Paella dish"
            />
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    {(description || "無詳細說明")}
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                <FormControl sx={{m: 1, width: 150}} variant="standard">
                    {/*<InputLabel htmlFor="standard-adornment-password">數量</InputLabel>*/}
                    <Input
                        sx={{
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                display: "none",
                            },
                        }}
                        id="standard-adornment-password"
                        type={'number'}
                        value={count}
                        onChange={(e) => {
                            setCount(Number(e.target.value).toString())
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => {
                                        setCount(count + 1)
                                    }}
                                    onMouseDown={() => {
                                    }}
                                >
                                    <AddIcon/>
                                </IconButton>
                            </InputAdornment>
                        }
                        startAdornment={
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={() => {
                                        setCount(count - 1)
                                    }}
                                    onMouseDown={() => {
                                    }}
                                >
                                    <RemoveIcon/>
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <Button variant="contained" onClick={() => {
                    let form = new FormData();
                    form.append("item", id);
                    form.append("quantity", count);

                    axios.post("/api/cart/addCart", form, {
                        withCredentials: true
                    }).then(r => {
                        console.log(r)
                    })
                }}>
                    加入購物車
                </Button>
            </CardActions>

        </Card>
    );
}