import {
    Container,
    Paper,
    TableContainer,
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

export default function Account() {
    return (<>
            <Container maxWidth={"xl"}>
                <Box sx={{mt: 2}}>
                    <Typography variant={"h5"}>我的帳戶</Typography>
                    <Box sx={{width: '100%', mt: 2}}>
                        <Paper sx={{width: '100%', mb: 2}}>
                            <TableContainer sx={{position: "relative"}}>

                            </TableContainer>
                        </Paper>
                    </Box>
                </Box>

                <Box sx={{mt: 2}}>
                    <Typography variant={"h5"}>付款方式</Typography>
                </Box>
                <Box>

                </Box>
            </Container>

        </>
    );
}