import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {Link, Route, Routes} from "react-router-dom";
import MainPage from "./Pages/MainPage";
import Page404 from "./Pages/Page404";
import IconButton from "@mui/material/IconButton";
import {Menu, MenuItem} from "@mui/material";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonIcon from '@mui/icons-material/Person';
import Cart from "./Pages/Cart";
import "./App.css";
import CheckOut from "./Pages/CheckOut";
import Shipping from "./Pages/Shipping";
import Account from "./Pages/Account";

export default function App() {

    /*
        useEffect( () => console.log("mount"), [] );
        useEffect( () => console.log("data1 update"), [ data1 ] );
        useEffect( () => console.log("any update") );
        useEffect( () => () => console.log("data1 update or unmount"), [ data1 ] );
        useEffect( () => () => console.log("unmount"), [] );
        */

    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="static">
                <Toolbar>
                    <Typography
                        variant="h6"
                        noWrap
                        component={Link}
                        to={"/"}
                        sx={{flexGrow: 1, display: {xs: 'none', sm: 'block'}}}
                    >
                        購物車
                    </Typography>
                    <Box>
                        <IconButton
                            component={Link}
                            to={"/cart"}
                            size="large"
                            color="inherit"
                        >
                            <ShoppingCartIcon fontSize="large"/>
                        </IconButton>
                    </Box>
                    <Box>
{/*                        <IconButton
                            component={Link}
                            to={"/account"}
                            size="large"
                            color="inherit"
                        >
                            <PersonIcon fontSize="large"/>
                        </IconButton>*/}
                        <Menu
                            id="menu-appbar"
                            // anchorEl={document.getElementsByTagName("body")[0]}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(false)}
                            onClose={() => {
                            }}
                        >
                            <MenuItem onClick={() => {
                            }}>Profile</MenuItem>
                            <MenuItem onClick={() => {
                            }}>My account</MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
            <Routes>
                <Route path={"/cart"} element={<Cart/>}/>
                <Route path={"/checkout"} element={<CheckOut/>}/>
                <Route path={"/checkout/:payment"} element={<Shipping/>}/>
                <Route path={"/account"} element={<Account/>}/>
                <Route path={"/"} element={<MainPage/>}/>
                <Route path={"/*"} element={<Page404/>}/>
            </Routes>

        </Box>
    );
}