import Box from "@mui/material/Box";
import {Container, Grid} from "@mui/material";
import Item from "../Components/Item";
import * as React from "react";
import {useEffect, useState} from "react";
import axios from "axios";

export default function MainPage() {
    const [goods, setGoods] = useState([]);

    useEffect(() => {
        axios.get("/api/getAllGoods", {
            withCredentials: true
        }).then(r => {
            setGoods(r.data.data)
        })
    }, []);

    return (
        <Container maxWidth={"xl"}>
            <Box sx={{mt: 2}}>
                <Grid container>
                    {goods.map((item, index) => {
                        return <Grid item xs={12} sm={6} md={4} key={index}>
                            <Item id={item.id} name={item.name} price={item.price} qty={item.quantity}
                                  images={item.images} description={item.description}/>
                        </Grid>
                    })}
                </Grid>
            </Box>
        </Container>
    )
}