import * as React from 'react';
import {
    Button,
    Checkbox,
    Container,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import axios from "axios";
import Typography from "@mui/material/Typography";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PersonIcon from "@mui/icons-material/Person";
import MoneyIcon from '@mui/icons-material/Money';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const headCells = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: '名稱',
    }, {
        id: 'qty',
        numeric: true,
        disablePadding: true,
        label: '數量',
    }, {
        id: 'price',
        numeric: true,
        disablePadding: true,
        label: '價格',
    }, {
        id: 'subtotal',
        numeric: true,
        disablePadding: true,
        label: '小計',
    }
];


export default function CheckOut() {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);

    const [cart, setCart] = React.useState([]);

    useEffect(() => {
        axios.get("/api/cart/getCart", {
            withCredentials: true
        }).then(r => {
            setCart(r.data.data)
        })
    }, []);


    const navigate = useNavigate();


    const [payment, setPayment] = React.useState("");

    const isSelected = (name) => selected.indexOf(name) !== -1;
    return (<>
            <Container maxWidth={"xl"}>
                <Box sx={{mt: 2}}>
                    <Typography variant={"h5"}>購物車</Typography>
                    <Box sx={{width: '100%', mt: 2}}>
                        <Paper sx={{width: '100%', mb: 2}}>
                            <TableContainer sx={{position: "relative"}}>
                                <Table
                                    sx={{minWidth: 750, minHeight: 100}}
                                    size={'medium'}
                                >
                                    <EnhancedTableHead
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        rowCount={cart.length}
                                        onClick={(e) => {

                                        }}
                                    />
                                    <TableBody>
                                        {cart.map((row, index) => {
                                            const isItemSelected = isSelected(row.item);
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={row.item}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            color="primary"
                                                            sx={{opacity: 0}}
                                                            checked={isItemSelected}
                                                            inputProps={{
                                                                'aria-labelledby': labelId,
                                                            }}
                                                            disabled
                                                            onClick={(event) => {

                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                    >
                                                        <Link to={`/item/${row.item}`}
                                                              style={{
                                                                  textDecoration: 'none',
                                                                  display: "block",
                                                                  height: 53,
                                                                  lineHeight: "53px",
                                                                  width: "100%",
                                                              }}
                                                        >
                                                            {row.goods.name}
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell padding="none">
                                                        {row.quantity}
                                                    </TableCell>
                                                    <TableCell padding="none">
                                                        NT$ {row.goods.price}
                                                    </TableCell>
                                                    <TableCell padding="none">
                                                        NT$ {row.goods.price * row.quantity}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Box>
                </Box>

                <Box sx={{mt: 2}}>
                    <Typography variant={"h5"}>付款方式</Typography>
                    <Paper sx={{width: '100%', mb: 2}}>
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton selected={payment === "bank"} onClick={() => {
                                    setPayment("bank")
                                }}>
                                    <ListItemIcon>
                                        <AccountBalanceIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="銀行轉帳"/>
                                </ListItemButton>
                            </ListItem>
                            <Divider/>
                            <ListItem disablePadding>
                                <ListItemButton selected={payment === "cash_on_delivery"} onClick={() => {
                                    setPayment("cash_on_delivery")
                                }}>
                                    <ListItemIcon>
                                        <MoneyIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="貨到付款"/>
                                </ListItemButton>
                            </ListItem>
                            <Divider/>
                            <ListItem disablePadding>
                                <ListItemButton disabled selected={payment === "credit_card"} onClick={() => {
                                    setPayment("credit_card")
                                }}>
                                    <ListItemIcon>
                                        <CreditCardIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="線上刷卡"/>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Paper>
                </Box>
                <Box textAlign={"end"}>
                    <Button variant={"contained"} onClick={() => {
                        if (payment === "") {
                            alert("請選擇付款方式")
                            return
                        }
                        navigate("/checkout/" + payment)
                    }}>下一步</Button>
                </Box>
            </Container>

        </>
    );
}

function EnhancedTableHead(props) {
    const {numSelected, rowCount, onClick} = props;
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        sx={{opacity: 0}}
                        disabled
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onClick={(event) => {
                            onClick(event)
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell padding="none" key={headCell.id}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}