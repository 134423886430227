import * as React from 'react';
import {
    Button,
    Checkbox,
    Container, FormControl, FormLabel, Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText, MenuItem,
    Paper, Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField
} from "@mui/material";
import {Link, useNavigate, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import axios from "axios";
import Typography from "@mui/material/Typography";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import MoneyIcon from '@mui/icons-material/Money';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import TW from "../taiwan_districts.json";

const headCells = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: '名稱',
    }, {
        id: 'qty',
        numeric: true,
        disablePadding: true,
        label: '數量',
    }, {
        id: 'price',
        numeric: true,
        disablePadding: true,
        label: '價格',
    }, {
        id: 'subtotal',
        numeric: true,
        disablePadding: true,
        label: '小計',
    }
];


export default function Shipping() {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);

    const [cart, setCart] = React.useState([]);

    useEffect(() => {
        axios.get("/api/cart/getCart", {
            withCredentials: true
        }).then(r => {
            setCart(r.data.data)
        })
    }, []);


    const navigate = useNavigate();


    // const [payment, setPayment] = React.useState("");
    const payment = {
        bank: <ListItem>
            <ListItemIcon>
                <AccountBalanceIcon/>
            </ListItemIcon>
            <ListItemText primary="銀行轉帳"/>
        </ListItem>,
        cash_on_delivery: <ListItem>
            <ListItemIcon>
                <MoneyIcon/>
            </ListItemIcon>
            <ListItemText primary="貨到付款"/>
        </ListItem>,
        credit_card: <ListItem>
            <ListItemIcon>
                <CreditCardIcon/>
            </ListItemIcon>
            <ListItemText primary="線上刷卡"/>
        </ListItem>,
    }

    useEffect(() => {
        if (!payment[params.payment]) {
            navigate("/checkout")
        }
    }, []);

    const isSelected = (name) => selected.indexOf(name) !== -1;


    const [shipping, setShipping] = React.useState({
        username: "",
        name: "",
        tel: "",
        email: "",
        area: "",
        city: "",
        address: "",
        note: "",
    });

    let isDev = (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
    const params = useParams();
    return (<>
            <Container maxWidth={"xl"}>
                <Box sx={{mt: 2}}>
                    <Typography variant={"h5"}>購物車</Typography>
                    <Box sx={{width: '100%', mt: 2}}>
                        <Paper sx={{width: '100%', mb: 2}}>
                            <TableContainer sx={{position: "relative"}}>
                                <Table
                                    sx={{minWidth: 750, minHeight: 100}}
                                    size={'medium'}
                                >
                                    <EnhancedTableHead
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        rowCount={cart.length}
                                        onClick={(e) => {

                                        }}
                                    />
                                    <TableBody>
                                        {cart.map((row, index) => {
                                            const isItemSelected = isSelected(row.item);
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={row.item}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            color="primary"
                                                            sx={{opacity: 0}}
                                                            checked={isItemSelected}
                                                            inputProps={{
                                                                'aria-labelledby': labelId,
                                                            }}
                                                            disabled
                                                            onClick={(event) => {

                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                    >
                                                        <Link to={`/item/${row.item}`}
                                                              style={{
                                                                  textDecoration: 'none',
                                                                  display: "block",
                                                                  height: 53,
                                                                  lineHeight: "53px",
                                                                  width: "100%",
                                                              }}
                                                        >
                                                            {row.goods.name}
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell padding="none">
                                                        {row.quantity}
                                                    </TableCell>
                                                    <TableCell padding="none">
                                                        NT$ {row.goods.price}
                                                    </TableCell>
                                                    <TableCell padding="none">
                                                        NT$ {row.goods.price * row.quantity}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Box>
                </Box>

                <Box sx={{mt: 2}}>
                    <Typography variant={"h5"}>付款方式</Typography>
                    <Paper sx={{width: '100%', mb: 2}}>
                        <List>
                            {/*{params}*/}
                            {payment[params.payment]}
                        </List>
                    </Paper>
                </Box>


                <Box sx={{mt: 2}}>
                    <Typography variant={"h5"}>運送方式</Typography>
                    <Grid container>
                        <Grid item xs={6}>
                            <Paper sx={{width: '100%', p: 2,}}>
                                <TextField label="姓名" variant="outlined" fullWidth sx={{m: 1, ml: 0}}
                                           value={shipping.name} onChange={(e) => {
                                    setShipping({
                                        ...shipping,
                                        name: e.target.value
                                    })
                                }}/>
                                <TextField label="電話" variant="outlined" fullWidth sx={{m: 1, ml: 0}}
                                           value={shipping.tel} onChange={(e) => {
                                    setShipping({
                                        ...shipping,
                                        tel: e.target.value
                                    })
                                }}/>
                                <TextField label="Email" variant="outlined" fullWidth sx={{m: 1, ml: 0}}
                                           value={shipping.email} onChange={(e) => {
                                    setShipping({
                                        ...shipping,
                                        email: e.target.value
                                    })
                                }}/>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormControl variant="outlined" fullWidth sx={{m: 1, ml: 0}}>
                                            <FormLabel>縣/市</FormLabel>
                                            <Select displayEmpty value={shipping.area}
                                                    onChange={(e) => {
                                                        setShipping({
                                                            ...shipping,
                                                            area: e.target.value
                                                        })
                                                    }}
                                            >
                                                <MenuItem value={""}>請選擇</MenuItem>
                                                {TW.map((item, index) => {
                                                    return <MenuItem key={index}
                                                                     value={item.name}>{item.name}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl variant="outlined" fullWidth sx={{m: 1, ml: 0}}>
                                            <FormLabel>鄉/鎮/市/區</FormLabel>
                                            <Select displayEmpty value={shipping.city}
                                                    onChange={(e) => {
                                                        setShipping({
                                                            ...shipping,
                                                            city: e.target.value
                                                        })
                                                    }}
                                            >
                                                <MenuItem value={""}>請選擇</MenuItem>
                                                {TW.find((item) => {
                                                    return item.name === shipping.area
                                                })?.districts.map((item, index) => {
                                                    return <MenuItem key={index}
                                                                     value={item.name}>{item.name}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>

                                    </Grid>
                                </Grid>
                                <TextField label="地址" variant="outlined" fullWidth sx={{m: 1, ml: 0}}
                                           value={shipping.address}
                                           onChange={(e) => {
                                               setShipping({
                                                   ...shipping,
                                                   address: e.target.value
                                               })
                                           }}/>
                                <TextField label="備註" variant="outlined" fullWidth sx={{m: 1, ml: 0}} multiline
                                           value={shipping.note}
                                           rows={3}
                                           onChange={(e) => {
                                               setShipping({
                                                   ...shipping,
                                                   note: e.target.value
                                               })
                                           }}/>

                            </Paper>
                        </Grid>
                        <Grid item xs={6}>

                            <Paper sx={{ml: 6, height: "100%"}}>

                            </Paper>

                        </Grid>
                    </Grid>
                </Box>


                <Box textAlign="end" mb={2} mt={2}>
                    {isDev ? <Button onClick={(e) => {
                        setShipping({
                            area: "高雄市",
                            note: "無",
                            address: "自強三路5號15樓之30",
                            tel: "0917115832",
                            city: "苓雅區",
                            name: "江晉緯",
                            email: "cces1004406@gmail.com",
                            username: null
                        })
                    }}>載入測試資料</Button> : <></>}
                    <Button variant={"contained"} onClick={() => {
                        let form = new FormData();
                        form.append("area", shipping.area);
                        form.append("city", shipping.city);
                        form.append("address", shipping.address);
                        form.append("note", shipping.note);
                        form.append("name", shipping.name);
                        form.append("email", shipping.email);
                        form.append("tel", shipping.tel);
                        form.append("payment", params.payment);

                        axios.post("/api/cart/submit", form, {
                            withCredentials: true
                        }).then(r => {
                            // setCart(r.data.data)
                        })
                    }}>送出訂單</Button>
                </Box>
            </Container>

        </>
    );
}

function EnhancedTableHead(props) {
    const {numSelected, rowCount, onClick} = props;
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        sx={{opacity: 0}}
                        disabled
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onClick={(event) => {
                            onClick(event)
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell padding="none" key={headCell.id}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}