import * as React from 'react';
import {
    Button,
    Checkbox,
    Container, FormControl, Grid, Input, InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import axios from "axios";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from '@mui/icons-material/Delete';

const headCells = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: '名稱',
    }, {
        id: 'qty',
        numeric: true,
        disablePadding: true,
        label: '數量',
    }, {
        id: 'price',
        numeric: true,
        disablePadding: true,
        label: '價格',
    }, {
        id: 'subtotal',
        numeric: true,
        disablePadding: true,
        label: '小計',
    }, {
        id: 'action',
        numeric: true,
        disablePadding: true,
        label: '動作',
    }
];


export default function Cart() {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);

    const [cart, setCart] = React.useState([]);

    useEffect(() => {
        axios.get("/api/cart/getCart", {
            withCredentials: true
        }).then(r => {
            setCart(r.data.data)
        })
    }, []);

    const updateCart = (item, qty) => {
        let form = new FormData();
        form.append("item", item);
        form.append("quantity", qty);
        axios.post("/api/cart/updateCart", form, {
            withCredentials: true
        }).then(r => {
            // setCart(r.data.data)
        })
    }
    const navigate = useNavigate();

    const isSelected = (name) => selected.indexOf(name) !== -1;
    return (<>
            <Container maxWidth={"xl"}>
                <Box sx={{mt: 2}}>
                    <Typography variant={"h5"}>購物車</Typography>
                    <Box sx={{width: '100%', mt: 2}}>
                        <Paper sx={{width: '100%', mb: 2}}>
                            <TableContainer sx={{position: "relative"}}>
                                <Table
                                    sx={{minWidth: 750, minHeight: 100}}
                                    size={'medium'}
                                >
                                    <EnhancedTableHead
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        rowCount={cart.length}
                                        onClick={(e) => {

                                        }}
                                    />
                                    <TableBody>
                                        {cart.map((row, index) => {
                                            const isItemSelected = isSelected(row.item);
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={row.item}
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            color="primary"
                                                            checked={isItemSelected}
                                                            inputProps={{
                                                                'aria-labelledby': labelId,
                                                            }}
                                                            disabled
                                                            onClick={(event) => {

                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                    >
                                                        <Link to={`/item/${row.item}`}
                                                              style={{
                                                                  textDecoration: 'none',
                                                                  display: "block",
                                                                  height: 53,
                                                                  lineHeight: "53px",
                                                                  width: "100%",
                                                              }}
                                                        >
                                                            {row.goods.name}
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell padding="none">
                                                        <FormControl sx={{m: 1, width: 150}} variant="standard">
                                                            <Input
                                                                sx={{
                                                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                                                        display: "none",
                                                                    },
                                                                }}
                                                                id="standard-adornment-password"
                                                                type={'number'}
                                                                value={row.quantity}
                                                                onChange={(e) => {
                                                                    setCart(cart.map((item) => {
                                                                        if (item.item === row.item) {
                                                                            item.quantity = Number(e.target.value)
                                                                        }
                                                                        return item
                                                                    }))
                                                                    updateCart(row.item, row.quantity)
                                                                }}
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            disabled={row.quantity >= row.goods.quantity}
                                                                            onClick={() => {
                                                                                setCart(cart.map((item) => {
                                                                                    if (item.item === row.item) {
                                                                                        item.quantity += 1
                                                                                    }
                                                                                    return item
                                                                                }))
                                                                                updateCart(row.item, row.quantity)

                                                                            }}
                                                                        >
                                                                            <AddIcon/>
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                                startAdornment={
                                                                    <InputAdornment position="start">
                                                                        <IconButton
                                                                            disabled={row.quantity <= 1}
                                                                            onClick={() => {
                                                                                setCart(cart.map((item) => {
                                                                                    if (item.item === row.item) {
                                                                                        if (item.quantity - 1 >= 1) {
                                                                                            item.quantity -= 1
                                                                                        }
                                                                                    }
                                                                                    return item
                                                                                }))
                                                                                updateCart(row.item, row.quantity)
                                                                            }}
                                                                        >
                                                                            <RemoveIcon/>
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                }
                                                            />
                                                        </FormControl>
                                                    </TableCell>
                                                    <TableCell padding="none">
                                                        NT$ {row.goods.price}
                                                    </TableCell>
                                                    <TableCell padding="none">
                                                        NT$ {row.goods.price * row.quantity}
                                                    </TableCell>
                                                    <TableCell padding="none">
                                                        <IconButton color={"error"} onClick={() => {
                                                            setCart(cart.filter((item) => {
                                                                return item.item !== row.item
                                                            }))
                                                            updateCart(row.item, -1)
                                                        }}>
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Box>
                </Box>
                <Box textAlign={"end"}>
                    <Button variant={"contained"} onClick={() => {
                        navigate("/checkout")
                    }}>下一步</Button>
                </Box>
            </Container>

        </>
    );
}

function EnhancedTableHead(props) {
    const {numSelected, rowCount, onClick} = props;
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        disabled
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onClick={(event) => {
                            onClick(event)
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell padding="none" key={headCell.id}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}